<template>
  <div class="container mt-3">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item active">Rujukan</li>
      </ol>
    </nav>

    <div
      v-if="!navTo"
      class="w-100 d-flex justify-content-center align-items-center"
      style="height: calc(100vh - 255px);"
    >
      <div class="row mx-0">
        <div class="col-12 text-center mb-5">
          <h3 class="fw-bolder">KATEGORI</h3>
        </div>
        <div class="col-12">
          <div class="row mx-0">
            <div class="col-12 col-md-6 col-lg-3 mb-3">
              <div
                class="card gallery-icon-card"
                @click="navigateTo('flipbook')"
              >
                <div class="card-body text-center">
                  <div class="row mx-0">
                    <div
                      class="col-3 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <div class="mb-3 icon-img">
                        <img
                          style="height:100%;"
                          src="./../../assets/img/gallery_icon_1.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="col-9 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <h4>e-Buku Ibadat Haji</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3 mb-3"
              @click="navigateTo('past-question')"
            >
              <div class="card gallery-icon-card">
                <div class="card-body text-center">
                  <div class="row mx-0">
                    <div
                      class="col-3 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <div class="mb-3 icon-img">
                        <img
                          style="height:100%;"
                          src="./../../assets/img/gallery_icon_2.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="col-9 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <h4>Soal Jawab Ibadat</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3 mb-3"
              @click="navigateTo('video')"
            >
              <div class="card gallery-icon-card">
                <div class="card-body text-center">
                  <div class="row mx-0">
                    <div
                      class="col-3 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <div class="mb-3 icon-img">
                        <img
                          style="height:100%;"
                          src="./../../assets/img/gallery_icon_3.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="col-9 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <h4>Video</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3 mb-3"
              @click="navigateTo('image')"
            >
              <div class="card gallery-icon-card">
                <div class="card-body text-center">
                  <div class="row mx-0">
                    <div
                      class="col-3 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <div class="mb-3 icon-img">
                        <img
                          style="height:100%;"
                          src="./../../assets/img/gallery_icon_4.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="col-9 col-md-12 d-flex align-items-center justify-content-center"
                    >
                      <h4>Infografik</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mt-2">
          <p>Pilih kategori untuk lihat setiap rujukan yang tersedia</p>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-md-3 p-1">
        <div class="px-2 overflow-hidden">
          <div class="w-100 p-3">
            <span role="button" class="text-muted" @click="navigateTo(null)"
              ><i class="bi bi-arrow-left"></i> Kembali</span
            >
          </div>
        </div>
        <div class="bg-white rounded px-2 overflow-hidden mt-4">
          <div class="w-100 p-3">
            <h6 class="mb-3 fw-regular">Carian kandungan</h6>
            <div class="border rounded position-relative mb-4">
              <input
                type="text"
                class="form-control border-0 input-searching m-0"
                v-model="search"
                placeholder="Cari..."
                @keydown.enter="handleSearchingKeyword(search)"
              />
              <div class="position-absolute end-0 me-2 top-0 mt-1">
                <i class="ri-search-line" style="color: #dee2e6" />
              </div>
            </div>
            <!-- <small class="extra-small d-block fw-bold mt-4 ms-1 text-grey"
              >TUNJUK:</small
            >
            <ul class="list-group mt-1">
              <li
                v-for="filter in listOfFilterType"
                :key="filter.title"
                class="list-group-item border-0 rounded"
                @click="handleSelectCategory(filter.title)"
                :style="
                  !filter.show
                    ? 'cursor: pointer;'
                    : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                "
              >
                <div class="d-flex align-items-center">
                  <i
                    :class="`${filter.icon} fs-6 me-2 text-grey`"
                    :style="filter.show && 'color: #006F00 !important'"
                  />
                  <p
                    class="mb-0 text-grey"
                    :style="filter.show && 'color: #006F00 !important'"
                  >
                    {{ helper.snakeToTitleCase(filter.showTitle) }}
                  </p>
                </div>
              </li>
            </ul> -->
          </div>
          <!-- Comment for a moment -->
          <div class="w-100 p-3 mb-2" style="background-color: #f3f7fb">
            <!-- <small class="extra-small d-block fw-bold ms-1 text-grey">TAGS</small> -->
            <small class="extra-small d-block fw-bold ms-1 text-grey mb-3"
              >KATEGORI</small
            >
            <span
              v-for="tag in listOfTag"
              :key="tag.id"
              :class="
                selectedtags.find((x) => x == tag.attributes.name)
                  ? 'badge rounded me-2 my-2 fw-normal'
                  : 'badge rounded text-muted me-2 my-2 fw-normal'
              "
              @click="selectedTag(tag)"
              :style="
                selectedtags.find((x) => x == tag.attributes.name)
                  ? 'cursor: pointer; background-color: #F3FEEE; color: #006F00'
                  : 'cursor: pointer; background-color: #e1e6ed;'
              "
              >{{ tag.attributes.name }}</span
            >
          </div>
          <!-- Comment for a moment -->
        </div>
      </div>
      <div class="col-md-9 mb-4 p-1">
        <div class="px-1" style="min-height: 70vh">
          <div
            class="
              row
              mx-0
              bg-white
              rounded
              justify-content-center
              align-items-center
              text-center
            "
            style="min-height: 70vh"
            v-if="isProcessing"
          >
            <p class="text-muted">Loading ...</p>
          </div>
          <div v-else>
            <!-- <h3 class="pt-3 px-2 mb-4">Media</h3> -->
            <div>
              <div
                class="row mx-0"
                style="margin-bottom: 12px"
                v-for="type in filterArrayType(
                  listOfFilterType.find((x) => x.show == true).title,
                  listOfFilterType
                )"
                :key="type.title"
              >
                <div
                  class="p-3"
                  v-if="filterArrayGaleri(type.title, listOfGaleri).length > 0 && type.title != 'all'"
                >
                  <h2 class="px-2 mb-3 fw-bold mb-4" v-if="type.title != 'all'">
                    {{ type.showTitle.toUpperCase() }}
                  </h2>
                  <div class="row mx-0 bg-white rounded p-3">
                    <div
                      class="col-lg-2 col-md-6 p-1"
                      v-show="type.title != 'all'"
                      v-for="(program, i) in filterArrayGaleri(
                        type.title,
                        listOfGaleri
                      )"
                      :key="i"
                      style="cursor: pointer"
                    >
                      <div class="card overflow-hidden shadow-md">
                        <div
                          @click="openModal(program, type)"
                          @touchstart="hoverProgramId = program.id"
                          @mouseover="hoverProgramId = program.id"
                          @mouseleave="hoverProgramId = null"
                          :class="
                            type.title == 'image'
                              ? 'position-relative w-100 bg-default-thumbnail image'
                              : type.title == 'video'
                              ? 'position-relative w-100 bg-default-thumbnail video'
                              : type.title == 'flipbook'
                              ? 'position-relative w-100 bg-default-thumbnail book'
                              : 'position-relative w-100 bg-default-thumbnail default'
                          "
                          :style="`${
                            getImgProgramImg(program) != null &&
                            `background-image: url(${getImgProgramImg(
                              program
                            )}) !important`
                          }`"
                          style="display: flex; justify-content: center; align-items: center;"
                        ></div>
                      </div>
                      <p class="fw-regular text-center mt-2 mb-0 label-custom">
                        {{
                          program && program.attributes
                            ? program.attributes.title
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="p-3"
                  v-if="pastQuestion && pastQuestion.data.length > 0 && type.title == 'past-question'"
                >
                  <h2
                    class="px-2 mb-3 fw-bold mb-4"
                    v-if="type.title == 'past-question'"
                  >
                    {{ type.showTitle.toUpperCase() }}
                  </h2>
                  <div
                    v-if="type.title == 'past-question' && pastQuestion != null"
                  >
                    <div
                      class="bg-white rounded p-3 mb-3"
                      v-for="(question, ind) in pastQuestion.data"
                      :key="question.id"
                    >
                      <div
                        class="row mx-0 mb-4"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#pastquestion-answer-${ind}`"
                        role="button"
                      >
                        <div class="col-12">
                          <p class="fw-bolder mb-2">
                            {{ ind + 1 }}. {{ question.attributes.question }}
                          </p>
                          <div
                            class="collapse"
                            :id="`pastquestion-answer-${ind}`"
                          >
                            <p>{{ question.attributes.answer }}</p>
                            <span class="section-label">{{
                              question.attributes.section
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        pastQuestion != null &&
                        pastQuestion.meta &&
                        pastQuestion.meta.pagination &&
                        pastQuestion.meta.pagination.total > 5 && 
                        pastQuestion.meta.pagination.page != pastQuestion.meta.pagination.pageSize
                      "
                    >
                      <button
                        class="btn text-decoration-underline"
                        @click="loadMorePastQuestions()"
                      >
                        Lagi ..
                      </button>
                    </div>
                  </div>
                </div>

                <!-- <p
                  class="px-2 mb-4 text-muted"
                  v-if="
                    filterArrayGaleri(type.title, listOfGaleri).length == 0 &&
                    type.title != 'past-question'
                  "
                >
                  Tiada.
                  {{filterArrayGaleri(type.title, listOfGaleri).length}}
                </p>
                <p
                  class="px-2 mb-4 text-muted"
                  v-if="
                    pastQuestion != null &&
                    pastQuestion.data.length == 0 &&
                    type.title == 'past-question'
                  "
                >
                  Tiada.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-img
      :data="selectedIMG && selectedIMG.url && selectedIMG.url.includes('https://') ? selectedIMG.url : `https://${selectedIMG.url}`"
      :title="selectedIMG.title"
      :show="showmodal.img"
      @close="closeModal()"
    />
    <modal-video
      :data="selectedVideo && selectedVideo.url && selectedVideo.url.includes('https://') ? selectedVideo.url : `https://${selectedVideo.url}`"
      :title="selectedIMG.title"
      :show="showmodal.video"
      @close="closeModal()"
    />
    <div class="modal" ref="modalOption">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Pilih Paparan</h5>
            <button
              type="button"
              class="btn-close"
              @click="hideModal()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-between">
              <!--
              <button class="btn border mx-3" style="height: 23rem;" @click="goto(selectedProgram)"> 
                <img src="../../assets/img/open-book.png" class="mb-5" style="width: 50%"/>
                <h4>e-Buku</h4>
              </button>
               -->
              <button
                class="btn border"
                @click="viewPDF(selectedProgram.attributes.file.data.attributes.url)"
              >
                <img
                  src="../../assets/img/pdf_2.png"
                  class="mb-5"
                  style="width: 50%"
                />
                <h4>PDF</h4>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import moment from "moment";
import API from "../../utils/API";
import modal from "./ModalPic.vue";
import ModalVideo from "./ModalVideo.vue";
import Helper from "../../utils/helper";
import { mapGetters } from "vuex";

const initailFilterArray = [
  {
    title: "all",
    showTitle: "Semua",
    show: true,
    icon: "ri-file-list-3-fill",
  },
  {
    title: "flipbook",
    // showTitle: "Flipbook",
    showTitle: "e-buku Ibadat Haji",
    show: false,
    icon: "ri-book-fill",
  },
  {
    title: "past-question",
    // showTitle: "Soalan Lepas",
    showTitle: "Soal Jawab Ibadat",
    show: false,
    icon: "ri-book-open-fill",
  },
  {
    title: "video",
    showTitle: "Video",
    show: false,
    icon: "ri-movie-fill",
  },
  {
    title: "image",
    showTitle: "Infografik",
    show: false,
    icon: "ri-image-fill",
  },
];
export default {
  name: "galeri",
  data() {
    return {
      navTo: null, // 'flipbook', 'past-question', 'video', 'image'
      timeout: 0,
      isProcessing: true,
      helper: Helper,
      search: "",
      listOfFilterType: initailFilterArray,
      listOfGaleri: [],
      pastQuestion: null,
      listOfTag: [],
      selectedtags: [],
      selectedIMG: {
        url: null,
        title: "",
      },
      showmodal: {
        video: false,
        img: false,
      },
      selectedVideo: {
        url: null,
        title: "",
      },
      selectedProgram: null,
      hoverProgramId: null,
      showButton: false,
    };
  },
  watch: {
    // $route(to, from) {
    //   console.log('to : ', to)
    // },
    search: {
      async handler() {
        if (this.search.length == 0) {
          await this.getGaleri();
          await this.getpastQuestions();
        } else {
          this.isProcessing = true;
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.handleSearchingKeyword(this.search);
          }, 1000);
        }
      },
      immediate: false,
    },
  },
  components: { "modal-img": modal, "modal-video": ModalVideo },
  computed: {
    ...mapGetters(["currentUser", "currentUserAccessType"]),
  },
  methods: {
    openModal(program, type) {
      if (type.title == 'flipbook'){
        this.selectedProgram = program;
        $(this.$refs.modalOption).modal('show');
      } else {
        this.goto(program);
      }
    },
    hideModal() {
      $(this.$refs.modalOption).modal('hide');
    },
    navigateTo(_data){
      if(!_data){
        this.navTo = null
        this.$router.replace({'query': null})
      }

      this.navTo = _data
      this.$router.push({ query: { section: _data } })
      this.handleSelectCategory(_data)
    },
    filterArrayType(_type, array) {
      const newItem = [...array];
      if (_type == "all") {
        return newItem.filter((x) => x.title != "all");
      } else {
        return newItem.filter((x) => x.title == _type);
      }
    },
    filterArrayGaleri(_type, array) {
      const newItem = [...array];
      if (_type == "flipbook") {
        return newItem
          .filter((x) => x.attributes.type != "image")
          .filter((x) => x.attributes.type != "video");
      } else {
        return newItem.filter((x) => x.attributes.type == _type);
      }
    },
    getDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    getGaleri() {
      this.isProcessing = true;
      this.listOfGaleri = [];

      let userAccessTypesFilter
      if(!this.currentUserAccessType) userAccessTypesFilter = { name: { $eq: 'public' } }
      else userAccessTypesFilter = { id: { $eq: this.currentUserAccessType.id } }

      const queryParams = qs.stringify({
        populate: ['background_image', 'file'],
        filters: {
          user_access_types: userAccessTypesFilter,
          is_archived: {
            $eq: false
          }
        }
      }, {
        encodeValuesOnly: true
      })

      let url =
        `gallery-files?${queryParams}&pagination[pageSize]=300`;

      // if (this.currentUser && this.currentUser.role.name) {
      //   url += `&filters[role_access][$in]=${this.currentUser.role.name.toLowerCase()}`;
      // }

      if (this.selectedtags.length > 0) {
        for (let i = 0; i < this.selectedtags.length; i++) {
          url += `&filters[$and][0][tags][name][$eq]=${this.selectedtags[i]}`;
        }
      }
      if (this.search.length > 0) {
        url += `&filters[$and][0][title][$containsi]=${this.search}`;
      }

      API.get(url).then((res) => {
        this.isProcessing = false;
        this.listOfGaleri = res.data.data;
      });
    },
    async getpastQuestions(_page) {
      let page = 1;
      if (_page) page = _page;
      let urlTags = "";

      if (this.selectedtags.length > 0) {
        for (let i = 0; i < this.selectedtags.length; i++) {
          urlTags += `&filters[$and][0][tags][name][$eq]=${this.selectedtags[i]}`;
        }
      }

      let res;
      if (this.search.trim() == "") {
        //if no search
        res = await API.get(
          `past-questions?pagination[pageSize]=5&pagination[page]=${page}${urlTags}`
        );
      } else {
        let query = qs.stringify(
          {
            filters: {
              question: {
                $containsi: this.search,
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );

        res = await API.get(
          `past-questions?pagination[pageSize]=5&pagination[page]=${page}&${query}${urlTags}`
        );
      }
      if (page == 1) this.pastQuestion = res.data;
      else if (page > 1) {
        this.pastQuestion.data = this.pastQuestion.data.concat(res.data.data);
        this.pastQuestion.meta = res.data.meta;
      }
    },
    loadMorePastQuestions() {
      this.getpastQuestions(this.pastQuestion.meta.pagination.page + 1);
    },
    getTags() {
      API.get("tags").then((res) => {
        this.listOfTag = res.data.data;
      });
    },
    goto(data) {
      var _type = data.attributes.type;
      this.hideModal();
      if (_type == "image") {
        if (
          data &&
          data.attributes &&
          data.attributes.file &&
          data.attributes.file.data &&
          data.attributes.file.data.attributes
        ) {
          this.selectedIMG = {
            url: data.attributes.file.data.attributes.url.includes("https://")
              ? data.attributes.file.data.attributes.url
              : "https://" + data.attributes.file.data.attributes.url,
            title: data.attributes.title,
          };
          this.showmodal = {
            video: false,
            img: true,
          };
        }
      } else if (_type == "video") {
        if (
          data &&
          data.attributes
          //   data.attributes.file &&
          //   data.attributes.file.data &&
          //   data.attributes.file.data.attributes
        ) {
          this.selectedVideo = {
            url: data.attributes.file && data.attributes.file.data ? data.attributes.file.data.attributes.url : null,
            title: data.attributes.title,
          }
          this.selectedIMG = {
            ...this.selectedIMG,
            // url: data.attributes.file.data.attributes.url.includes("https://")
            //   ? data.attributes.file.data.attributes.url
            //   : "https://" + data.attributes.file.data.attributes.url,
            title: data.attributes.title,
          };
          this.showmodal = {
            video: true,
            img: false,
          };
        }
      } else {
        this.$router.push({
          path: `view-pdf-file/${data.id}`,
        });
      }
    },
    viewPDF(file) {
  const url = file.startsWith("https://") ? file : "https://" + file;
  window.open(url, "_blank");
},
    async selectedTag(tag) {
      const findItem = this.selectedtags.find((x) => x == tag.attributes.name);
      if (findItem == undefined) {
        this.selectedtags.push(tag.attributes.name);
      } else {
        this.selectedtags = this.selectedtags.filter(
          (x) => x != tag.attributes.name
        );
      }
      await this.getGaleri();
      await this.getpastQuestions();
    },
    closeModal() {
      this.showmodal = {
        video: false,
        img: false,
      };
      this.selectedIMG = {
        url: null,
        title: "",
      };
      this.selectedVideo = {
        url: null,
        title: "",
      };
    },
    async handleSearchingKeyword(search) {
      this.search = search;

      await this.getGaleri();
      await this.getpastQuestions();
    },
    async handleSelectCategory(title) {
      console.log('title : ', title)
      this.listOfFilterType = this.listOfFilterType.map((x) => {
        return {
          ...x,
          show: x.title == title ? true : false,
        };
      });
      if (title != "all") {
        await this.getGaleriType(title);
      } else {
        await this.getTags();
      }
    },
    getImgProgramImg(obj) {
      if (
        obj &&
        obj.attributes &&
        obj.attributes.background_image &&
        obj.attributes.background_image.data &&
        obj.attributes.background_image.data.attributes
      ) {
        return obj.attributes.background_image.data.attributes.url.includes(
          "https://"
        )
          ? obj.attributes.background_image.data.attributes.url
          : "https://" + obj.attributes.background_image.data.attributes.url;
      } else {
        return null;
      }
    },
    getGaleriType(_type) {
      console.log(_type);
      API.get(
        `gallery-file-types?populate=tags&filters[$and][0][name][$eq]=${_type}`
      ).then((res) => {
        if (
          res.data.data.length > 0 &&
          res.data.data[0].attributes.tags.data.length > 0
        ) {
          this.listOfTag = res.data.data[0].attributes.tags.data;
        }
      });
    },
  },
  async mounted() {
    await this.getGaleri();
    await this.getpastQuestions();
    await this.getTags();

    if(this.$route.query.section && this.$route.query.section != ''){
      this.navTo = this.$route.query.section
      this.handleSelectCategory(this.$route.query.section)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";

.program-box {
  width: 198px;
  height: 289px;
  border-radius: 5px;
  line-height: 1.5rem;
  transition: all ease-in-out 150ms;

  .program-box-body {
    color: $white;
    line-height: 1.1rem;
    padding: 15px;
    width: 100%;
    background-image: linear-gradient(0deg, #213621, transparent);
    transition: all ease-in-out 150ms;

    :hover {
      // color: #006f00 !important;
      border: none !important;
      background-image: none !important;
    }

    small {
      display: block;
      margin: 3px 0px;
    }
  }

  @media (max-width: 500px) {
    .is-mobile {
      width: 100% !important;
      height: 100% !important;
      justify-content: center;
      align-items: center;
      display: flex;
      background: #e1e3e7;
    }
  }
  :hover {
    color: #006f00 !important;
    transition: all ease-in-out 150ms;
    background-image: linear-gradient(0deg, #f3feee, #f3feee50);
  }
}

.input-searching {
  &::-webkit-input-placeholder {
    font-size: 14px;
    color: #dee2e6;
  }
}

.label-custom {
  font-size: 0.7rem !important;
  line-height: 0.78rem !important;
}

.bg-default-thumbnail {
  background-image: url("../../assets/img/random-img-placeholder/1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 225px;

  &.image {
    background-image: url("../../assets/img/random-img-placeholder/7.jpg") !important;
  }

  &.video {
    background-image: url("../../assets/img/random-img-placeholder/8.jpg") !important;
  }

  &.book {
    background-image: url("../../assets/img/random-img-placeholder/6.jpg") !important;
  }
}

.section-label {
  font-size: 12px;
  background-color: $gray-light-02;
  padding: 5px 10px;
  border-radius: 10px;
}

.card {
  &.gallery-icon-card {
    .card-body {
        padding: 40px 0px;
        cursor: pointer;

        .icon-img {
          height: 80px;

          @media (max-width: 767.98px) {
            height: 50px;
          }
        }

        @media (max-width: 767.98px) {
          padding: 10px 0px;
          height: 80px;
        }
    }
  }
}

.icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-size: cover;
  background-position: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

// .icon-type {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   padding: 10px;

//   i {
//     font-size: 1rem !important;
//     color: #00000030;
//   }
// }
</style>
