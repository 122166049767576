<template>
  <!-- Modal -->
  <div
    :class="!show ? 'modal' : 'modal d-block'"
    id="toggleMyModal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="toggleMyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            @click="handleClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="data"
            class="d-flex h-100 w-100 justify-content-center align-items-center"
          >
            <iframe
              width="80%"
              height="100%"
              :src="data"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div v-else>
            Tiada URL Data.
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="handleClose()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-video",
  props: ["data", "show", "close", "title"],
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>